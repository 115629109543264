<template>
  <div>
    <div class="top-banner">
      <div class="bg"></div>
      <div class="ban">
        <div class="title-big">American Delivery Service </div>
        <div class="title-small">
          <div>Experience hassle-free, timely delivery of your products.
          </div>
          <div>We allow you to add at most 200 packages in one order. </div>
        </div>
      </div>
    </div>
    <div class="cont">
      <div class="title1">Flexible Delivery Options</div>
      <div class="top-des">
        At Tailored Delivery Solutions, we understand that every customer has unique delivery requirements. That's why we
        offer a range of flexible delivery options designed to accommodate your specific needs. With our tailored
        approach, you can choose the delivery solution that works best for you. Here's how we provide flexibility in our
        delivery services.
      </div>
      <div class="paiban">
        <div class="word" style="margin-right:80px;">
          <img src="../../../assets/images/home/speed.png" alt="">
        </div>
        <div>
          <div class="title">Speed and Urgency</div>
          <div class="des">
            We offer expedited delivery services for time-sensitive shipments that require swift transportation. Whether
            it's a last-minute order or an urgent delivery, our team is equipped to handle your time-critical shipments
            efficiently and ensure they reach their destination promptly.
          </div>
        </div>
      </div>
      <div class="paiban">
        <div style="margin-right:80px;">
          <div class="title">Delivery Time Windows</div>
          <div class="des">
            We understand that receiving packages at specific times can be crucial for businesses and individuals alike.
            That's why we provide customizable delivery time windows, allowing you to select the most convenient timeframe
            for your deliveries. Our flexible scheduling options ensure that your packages arrive when it suits you best.
          </div>
        </div>
        <div class="word">
          <img src="../../../assets/images/home/delivery_time.png" alt="">
        </div>
      </div>
      <div class="paiban">
        <div class="word" style="margin-right:80px;">
          <img src="../../../assets/images/home/same_day.png" alt="">
        </div>
        <div>
          <div class="title">Same-Day and Next-Day Delivery</div>
          <div class="des">
            For those who require quick turnaround, we offer same-day and next-day delivery services. Whether you need
            documents, parcels, or products delivered urgently, our expedited delivery options ensure swift transportation
            and prompt arrival at the desired destination.
          </div>
        </div>
      </div>
      <div class="paiban">
        <div style="margin-right:80px;">
          <div class="title">Customized Solutions</div>
          <div class="des">
            We understand that each customer has unique delivery requirements. That's why we offer customized solutions
            tailored to your specific needs. Our team works closely with you to understand your delivery challenges and
            develop a personalized plan that meets your expectations and aligns with your business goals.
          </div>
        </div>
        <div class="word">
          <img src="../../../assets/images/home/customized.png" alt="">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="less" scoped>
.top-banner {
  box-sizing: border-box;
  position: relative;
  height: 538px;
  overflow: hidden;

  .bg {
    background-image: url('../../../assets/images/home/cangku11.png');
    background-size: cover;
    background-position: 50% 50%;
    height: 540px;
    filter: blur(2px);
  }

  .ban {
    width: 700px;
    position: absolute;
    z-index: 9;
    top: 50%;
    right: 50%;
    transform: translate(50%, -70%);
  }

  .title-big {
    font-weight: 500;
    font-size: 56px;
    color: #fff;
    text-align: center;
    line-height: 64px;
    padding-top: 166px;
  }

  .title-small {
    margin-top: 28px;
    font-weight: 400;
    font-size: 20px;
    text-align: center;
    line-height: 28px;
    color: #fff;
  }
}

.cont {
  width: 1300px;
  margin: 0 auto;
  padding: 60px 40px;
  box-sizing: border-box;
  line-height: 1.8;

  .top-des {
    font-size: 26px;
    font-weight: 400;
    color: var(--color-text-2);
    margin-bottom: 60px;
    margin-top: 60px;
  }

  .title {
    font-weight: 300;
    font-size: 38px;
    margin-bottom: 16px;
    color: #333;
  }

  .title1 {
    text-align: center;
    font-weight: 300;
    font-size: 56px;
    line-height: 53px;
    color: #333333;
  }

  .des {
    font-weight: 400;
    font-size: 24px;
    line-height: 1.8;
    color: #7D7D7D;
    padding-top: 10px;
  }

  .paiban {
    display: flex;
    justify-content: space-between;
    margin-top: 100px;

    .word {

      img {
        width: 560px;
        height: 400px;
        border-radius: 8px;
      }
    }
  }
}
</style>
